<template>
  <v-container>
    <h1>Impressum</h1>
    <p>
      <b>SMARTmedSolutions GmbH</b><br />
      Max-Ernst-Str. 4<br />
      D-50354 Hürth <br />
      E-Mail: info@smartmed-test.de<br />
      Registergericht: Amtsgericht Köln<br />
      Registernummer: HRB 100104<br />
      USt.-IDNr.: DE329552044
    </p>
    <p>
      <b>Geschäftsführung</b><br />
      Dr. Anke Müller-Peters
    </p>
    <p>
      <b>Verantwortlicher im Sinne von § 55 Abs. 2 RStV</b><br />
      Dr. Anke Müller-Peters <br />
      Haftungshinweis
    </p>
    <p>
      Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich. Plattform der
      EU-Kommission zur Online-Streitbeilegung: <a href="https://www.ec.europa.eu/consumers/odr">www.ec.europa.eu/consumers/odr</a>
    </p>
  </v-container>
</template>

<script>
export default {};
</script>
